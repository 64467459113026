import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Form, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { dispatch, getModel, ICourse, IModel, IParticipant, lireParticipantsACourse } from "../modele";

type loaderData = {
    model: IModel;
}

export async function loader() {
    let model = await getModel();
    return { model };
}
export async function action() {
    return null;
}

async function lireParticipantsACourseRestant(model: IModel, course: ICourse): Promise<IParticipant[]> {
    let participants = await lireParticipantsACourse(course);
    return participants.filter((participant) => {
        // Ce participant a-t-il déjà reçu un statut spécial (abandon, absence, ...) ?
        if (participant.statut !== 'Partant') {
            return false;
        }
        // La saisie a-t-elle déjà commencé ?
        if (model.arrivees[course.nom] === undefined) {
            return true;
        }
        // Ce participant a-t-il déjà été saisi dans les arrivées ?
        if (model.arrivees[course.nom].indexOf(participant.dossard) >= 0) {
            return false;
        }
        return true;
    });
}


export default function Arrivee() {
    const { courseID } = useParams();
    const { model } = useLoaderData() as loaderData;

    const navigate = useNavigate();
    const [dossard, setDossard] = useState(0);
    const [abandon, setAbandon] = useState('');

    const [erreur, setErreur] = useState('');
    const [participantsNonSaisiListe, setParticipantsNonSaisiListe] = useState([] as IParticipant[]);


    function verifieDossard(dossard: number): { participant: IParticipant | undefined, course: ICourse | undefined } {
        let refus = { participant: undefined, course: undefined };

        let participant = model.participants.find((item) => {
            return item.dossard === dossard;
        });
        let course = undefined;

        if (participant === undefined) {
            if (dossard > 100) { setErreur('Dossard inconnu'); } // (>100 pour attendre les 3 chiffres du dossard)
            return refus;
        } else {
            //Ce participant fait-il partie des critères de cette course ?
            course = model.courses.find((item) => item.nom === courseID);
            if (course === undefined) {
                setErreur('Erreur critique : impossible de trouver la course');
                return refus;
            }
            let niveauParticipant = participant.niveau;
            if (course.criteresNiveaux.indexOf(niveauParticipant) < 0) {
                setErreur('Ce dossard ne fait pas partie de cette course');
                return refus;
            }
            if (course.criteresSexe.indexOf(participant.sexe) < 0) {
                setErreur('Ce dossard ne fait pas partie de cette course');
                return refus;
            }
            if (model.arrivees[course.nom] !== undefined) {
                if (model.arrivees[course.nom].indexOf(dossard) >= 0) {
                    setErreur('Dossard déjà saisi');
                    return refus;
                }
            }
        }
        return { participant: participant, course: course };
    }


    function onDossardChange(event: ChangeEvent<HTMLInputElement>) {
        let dossardSaisi = 0;
        setErreur('');
        if (event.target.value !== '') {
            dossardSaisi = parseInt(event.target.value);
        }
        setDossard(dossardSaisi);
        let { participant, course } = verifieDossard(dossardSaisi);
        if ((participant !== undefined) && (course !== undefined)) {
            // Toujours partant ?
            if (participant.statut !== 'Partant') {
                if (!window.confirm(`Le participant ${dossardSaisi} est marqué comme ${participant.statut}, est-ce le bon ?`)) {
                    return;
                }
            }
            dispatch(
                'arrivee', {
                courseID: course.nom,
                dossard: dossardSaisi,
            }
            );
            setDossard(0);
            event.target.form?.submit(); // Force l'actualisation de l'affichage
        }
    }

    function onAbandonChange(event: ChangeEvent<HTMLInputElement>) {
        setAbandon(event.target.value);
        let dossardSaisi = 0;
        setErreur('');
        if (event.target.value !== '') {
            dossardSaisi = parseInt(event.target.value);
        }
        let { participant, course } = verifieDossard(dossardSaisi);
        if ((participant !== undefined) && (course !== undefined)) {
            dispatch(
                'abandon', {
                dossard: dossardSaisi,
            }
            );
            setAbandon('');
            event.target.form?.submit(); // Force l'actualisation de l'affichage
        }
    }

    function abandonDuDossard(event: MouseEvent<HTMLButtonElement>) {
        let target = event.target as HTMLButtonElement;
        let dossard = parseInt(target.id);
        //if (window.confirm(`${dossard} abandonne ?`)) 
        {
            dispatch('abandon', {
                dossard: dossard,
            });
        };
    }


    async function participantsNonSaisi() {
        let course = model.courses.find((item) => item.nom === courseID);
        if (course === undefined) {
            console.log('Erreur critique : impossible de trouver la course');
            return [];
        }

        let retour = await lireParticipantsACourseRestant(model, course);
        setParticipantsNonSaisiListe(retour);
        return retour;
    }

    useEffect(() => {
        participantsNonSaisi();
    }, [model]);


    return (
        <>
            <h1 className="titreEcran">{courseID} - arrivée</h1>

            <p className='actions'>Saisissez les arrivées du dernier au premier (en retirant les dossards du pic d'arrivée au fur et à mesure)</p>

            <Form method='GET' className='actions'>

                <input type='text' onChange={onDossardChange} value={dossard} autoFocus></input>
                <span className="erreur">{erreur}</span>
                <input type='text' onChange={onAbandonChange} value={abandon} placeholder="Abandon"></input>
            </Form >

            <div className="arriveesContainer">
                <table className='arrivee'>
                    <thead>
                        <tr>
                            <th>Ordre</th>
                            <th>Dossard</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseID && model.arrivees[courseID] && model.arrivees[courseID].map((item: number, index: number) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item}</td>
                                <td> {index === 0 && (
                                    <Form method='GET'>
                                        <button onClick={() =>
                                            courseID !== undefined && model.arrivees[courseID].length > 0 &&
                                            dispatch('arriveeAnnule', {
                                                nom: courseID,
                                                dossard: model.arrivees[courseID][0],
                                            })
                                        }
                                        >Annuler la dernière saisie</button>
                                    </Form>
                                )}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {participantsNonSaisiListe.length > 0 && (
                    <ul>Restants à saisir :
                        {
                            participantsNonSaisiListe.map(item =>
                                <li key={item.dossard}> {item.dossard}
                                    <button id={"" + item.dossard}
                                        onClick={abandonDuDossard}>Abandon</button>
                                </li>)
                        }
                    </ul>
                )}

                {participantsNonSaisiListe.length === 0 && (
                    <p>Tous les participants sont saisis. <button onClick={() => navigate('/listings')}>Consulter les listings</button></p>
                )}
            </div>
        </>);
}
