import { useEffect, useState } from "react";
import { Form, useLoaderData, useNavigate } from "react-router-dom";
import CourseDetail from "../Components/CourseDetail";
import { getModel, ICourse, IParticipant, lireParticipantsACourse } from "../modele";
import CourseSaisie from "../Components/CourseSaisie";

type loaderData = {
    courses: ICourse[];
    niveaux: string[];
    arrivees: any;
}

export async function loader() {
    const { courses, participants, arrivees } = await getModel();

    let niveaux: string[] = [];
    participants.forEach((participant) => {
        let nouveauNiveau = participant.niveau;
        if (niveaux.indexOf(nouveauNiveau) < 0) {
            niveaux.push(nouveauNiveau);
        }
    });

    return { courses, niveaux, arrivees };
}

export async function action() {
    return null;
}

export default function CoursesListe() {
    const navigate = useNavigate();
    const { courses, niveaux, arrivees } = useLoaderData() as loaderData;
    const [afficheSuppression, setAfficheSuppression] = useState(false);
    const [nbParticipantsNonInscrits, setNbParticipantsNonInscrits] = useState(0);


    async function lireNonInscrits() {
        let model = await getModel();
        let nonInscrits: IParticipant[] = [...model.participants];
        await Promise.all(model.courses.map(async (course) => {
            console.log(course.nom + '/' + nonInscrits.length);    
            const inscrits = await lireParticipantsACourse(course);
            await Promise.all(inscrits.map((participantInscrit) => {
                let index = nonInscrits.findIndex((participant) => participant.dossard === participantInscrit.dossard);
                if (index >= 0) {
                    console.log('splice');
                    nonInscrits.splice(index, 1);
                }
            }));
                
        }));
        console.log(nonInscrits.length);
        setNbParticipantsNonInscrits(nonInscrits.length);

    }

    useEffect(() => {
    lireNonInscrits();
    }, [courses]);

    useEffect(() => {}, [nbParticipantsNonInscrits]);

    return <>
        {!afficheSuppression && <p className="actions">
            <button onClick={() => setAfficheSuppression(true)}>Suppression...</button>
        </p>}
        <Form method='POST'>
            <CourseSaisie
                niveaux={niveaux}
                nbParticipantsNonInscrits={nbParticipantsNonInscrits} />
        </Form>

        {courses.map((item) => {
            return <Form method='POST' key={item.nom}>
                <CourseDetail course={item} 
                    afficheSuppression={afficheSuppression}
                    arrivees={arrivees[item.nom]}
                />
            </Form>;
        })
        }

    </>;
}