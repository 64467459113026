import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { ICourse } from "../modele";

type ICourseArriveeParams = {
    course: ICourse;
};

export default function CourseArriveeDetail({ course }: ICourseArriveeParams) {
    const navigate = useNavigate();

    return <Fragment key={course.nom} >
        <div className="courseArriveeDetail" onClick={() => navigate(`/arrivees/${course.nom}`)}>
            <span className='nomCourse'>{course.nom}</span>
        </div>
    </Fragment>
}