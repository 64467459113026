import { NavLink, Outlet, useLoaderData } from "react-router-dom";

export function Root() {
    return (<>
        <nav className="onglets">
            <ul>
                <li><NavLink to={'/'} className={({ isActive, isPending }) =>
                    isActive
                        ? "active"
                        : isPending
                            ? "pending"
                            : ""
                }>Accueil</NavLink></li>

                <li><NavLink to={'/participants'}
                    className={({ isActive, isPending }) =>
                        isActive
                            ? "active"
                            : isPending
                                ? "pending"
                                : ""
                    }>Participants</NavLink></li>

                <li><NavLink to={'/courses'}
                    className={({ isActive, isPending }) =>
                        isActive
                            ? "active"
                            : isPending
                                ? "pending"
                                : ""
                    }>Courses</NavLink></li>

                <li><NavLink to={'/arrivees'}
                    className={({ isActive, isPending }) =>
                        isActive
                            ? "active"
                            : isPending
                                ? "pending"
                                : ""
                    }>Arrivées</NavLink></li>

                <li><NavLink to={'/listings'}
                    className={({ isActive, isPending }) =>
                        isActive
                            ? "active"
                            : isPending
                                ? "pending"
                                : ""
                    }>Listings</NavLink></li>

            </ul>
        </nav>
        <div className="outlet">
            <Outlet />
        </div>
    </>);
}