import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom"
import { PDFDocument, PDFFont, PDFPage, StandardFonts, rgb } from 'pdf-lib' // npm install pdf-lib
import { useEffect, useState } from "react";
import { getParticipants, IParticipant } from "../modele";

type loaderData = {
    participants: IParticipant[];
}

export async function loader() {
    const participants = await getParticipants();
    return { participants };
}

export default function Listings() {
    const navigate = useNavigate();
    const {participants} = useLoaderData() as loaderData;

    const [csv, setCsv] = useState('');

    const [pdfDossardsURI, setPdfDossardsURI] = useState<string | null>(null);


    // Regénère les pdfs lorsque le modèle change
    useEffect(() => {
        regenerPDFs();
    }, [participants]);

    const regenerPDFs = async () => {
        // ATTENTION beaucoup de bibliothèques ne fonctionnent pas. Celle-ci fonctionne :
        // https://pdf-lib.js.org/#examples
        // npm install pdf-lib

        let nbElevesParPageMax = 60;

        // Dossards
        let timesRomanFont: PDFFont;
        PDFDocument.create()
            .then(async (pdfDoc) => {
                timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
                return pdfDoc;
            })
            .then((pdfDoc) => {
                let derniereClasse = '';
                let nbElevesImprimes = 0;
                let page: PDFPage | null = null;
                const fontSizeHeader = 14;
                const fontSizeLine = 12;
                let x = [ // Classe
                    50, // Dossard
                    100, // Nom
                    350, // Prénom
                    500]; // Sexe
                let yBase = 770;
                let colorHeader = rgb(0, 0.53, 0.71);
                let colorLine = rgb(0, 0, 0);
                // Les participants sont maintenus triés par Classe, Nom, Prénom (et donc par dossard, sauf modif)
                // (0, 0) correspond à en bas à gauche
                participants.map((item, index) => {
                    nbElevesImprimes++;
                    // Besoin d'une nouvelle page ?
                    if (derniereClasse !== item.classe
                        || nbElevesImprimes >= nbElevesParPageMax) {
                        page = pdfDoc.addPage();
                        derniereClasse = item.classe;
                        nbElevesImprimes = 1;
                        page.drawText('Dossard', {
                            x: x[0],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Nom', {
                            x: x[1],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Prénom', {
                            x: x[2],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });
                        page.drawText('Sexe', {
                            x: x[3],
                            y: yBase + 3,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });

                        // Titre
                        page.drawText(`Dossards ${item.classe}`, {
                            x: x[0],
                            y: yBase + 7 + fontSizeHeader,
                            size: fontSizeHeader,
                            font: timesRomanFont,
                            color: colorHeader,
                        });

                        // Grille horizontale
                        page.drawLine({ start: { x: x[0] - 2, y: yBase - 2 }, end: { x: 550, y: yBase - 2 }, color: rgb(0, 0, 0) });
                        page.drawLine({ start: { x: x[0] - 2, y: yBase + 2 + fontSizeHeader}, end: { x: 550, y: yBase + 2 + fontSizeHeader }, color: rgb(0, 0, 0) });
                        page.drawLine({ start: { x: x[0] - 2, y: 55 }, end: { x: 550, y: 55 }, color: rgb(0, 0, 0) });

                        // Grille verticale
                        x.map((item, index) => {
                            page?.drawLine({ start: { x: x[index] - 2, y: yBase + fontSizeHeader + 2 }, end: { x: x[index] - 2, y: 55 }, color: rgb(0, 0, 0) });
                        });
                        page.drawLine({ start: { x: x[3] + 50, y: yBase + fontSizeHeader + 2 }, end: { x: x[3] + 50, y: 55 }, color: rgb(0, 0, 0) });
                    }
                    if (!page) {
                        return;
                    }

                    // Participant
                    let yLigne = yBase - (fontSizeLine * nbElevesImprimes) - (fontSizeHeader - fontSizeLine);
                    page.drawText("" + item.dossard, {
                        x: x[0],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.nom, {
                        x: x[1],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.prenom, {
                        x: x[2],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    page.drawText(item.sexe, {
                        x: x[3],
                        y: yLigne,
                        size: fontSizeHeader,
                        font: timesRomanFont,
                        color: colorLine,
                    });
                    // Ligne de séparation toutes les 5 lignes
                    if (nbElevesImprimes %5 === 0) {
                    // pas beau page.drawLine({ start: { x: x[0] - 2, y: yLigne - 1 }, end: { x: 550, y: yLigne - 1 }, color: rgb(0.8, 0.8, 0.8) });
                    }

                });
                return pdfDoc;
            }).then((pdfDoc) => {
                pdfDoc.saveAsBase64().then((base64) => setPdfDossardsURI(`data:application/pdf;base64,${base64}`));
            });


        // CSV (pour 2024)
        let txt = participants.map((item) => {
            return `${item.dossard}\t${item.nom}\t${item.prenom}\t${item.classe}\t${item.sexe}`;
        });
        setCsv(txt.join('\n'));
    }

    return (
        <>
            <h1 className="titreEcran">Listings</h1>
            <ul className="listeListings">
                <li>Dossards par classe ({participants.length}) : {pdfDossardsURI && <a href={pdfDossardsURI} download='Dossards.pdf' >Dossards.pdf</a>}
                </li>
            </ul>
            Données pour <a href='https://www.famillegaillard.fr/cross'>ancienne version</a> : <textarea defaultValue={csv}></textarea>
        </>
    );
}