import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { ajouteCourse, ICourse, supprimeCourse } from "../modele";

type ICourseParams = {
    niveaux: string[];
    nbParticipantsNonInscrits: number;
};

export default function CourseSaisie({ niveaux, nbParticipantsNonInscrits }: ICourseParams) {
    const navigate = useNavigate();
    const [nom, setNom] = useState('');
    const [criteresNiveaux, setCriteresNiveaux] = useState([] as string[]);
    const [criteresSexe, setCriteresSexe] = useState([] as string[]);

    function onNiveauChange() {
        let niveauxSelectionne:string[] = [];
        niveaux.map((niveau) => {
            if ((document.getElementById(niveau) as HTMLInputElement).checked) {
                niveauxSelectionne.push(niveau);
            }
            return niveau;
        }
        );
        setCriteresNiveaux(niveauxSelectionne);
    }

    function onSexeChange() {
        let sexes: string[] = [];
        if ((document.getElementById('sexeF') as HTMLInputElement).checked) {
            sexes.push('F');
        }
        if ((document.getElementById('sexeM') as HTMLInputElement).checked) {
            sexes.push('M');
        }
        setCriteresSexe(sexes);
    }


    async function onAjouterCourse() {
        //TODO: vérifier la saisie !
        if (nom === '') {
            alert('Veuillez donner un nom à cette course');
            return;
        }

        if (criteresNiveaux.length === 0) {
            alert('Veuillez sélectionner au moins 1 niveau');
            return;
        }

        if (criteresSexe.length === 0) {
            alert('Veuillez sélectionner au moins 1 sexe');
            return;
        }

        let course = {
            nom: nom,
            criteresNiveaux: criteresNiveaux,
            criteresSexe: criteresSexe,
        };
        setNom('');
        setCriteresNiveaux([]);
        setCriteresSexe([]);
        await ajouteCourse(course);
    }


    return <Fragment >
        {niveaux.length === 0 && <a href='/participants/import'>Ajouter des participants pour commencer</a>}

        { niveaux.length > 0 && nbParticipantsNonInscrits > 0 && (
            <div className="ajouterUneCourse">Ajouter une course ({nbParticipantsNonInscrits} participants non encore inscrits) :
                <ul className="ajoutCourse">
                    <li>
                        Nom : <input type='text' value={nom} onChange={(event) => setNom(event.target.value)}></input> 
                        <button type='submit' onClick={onAjouterCourse}>Ajouter</button>
                    </li>
                    <li>Niveau(x) : {niveaux.map((niveau, index) => {
                        return <Fragment key={niveau}>
                            <input type='checkbox'
                                value={niveau}
                                onChange={onNiveauChange}
                                id={niveau}
                                key={niveau}
                                checked={
                                    criteresNiveaux.find((niveauSelectionne:string) => { return niveau === niveauSelectionne }) === undefined ? false : true
                                }></input>
                            <label key={niveau + '_lbl'}
                                htmlFor={niveau}>{niveau}</label></Fragment>
                    }

                    )}</li>
                    <li>Sexe(s) : <input type='checkbox' value='F' onChange={onSexeChange} id='sexeF' checked={
                        criteresSexe.find((sexeSelectionne:string) => { return 'F' === sexeSelectionne }) === undefined ? false : true
                    }></input> <label htmlFor="sexeF">F</label>
                        <input type='checkbox' value='M' onChange={onSexeChange} id='sexeM' checked={
                            criteresSexe.find((sexeSelectionne:string) => { return 'M' === sexeSelectionne }) === undefined ? false : true
                        }></input><label htmlFor="sexeM">M</label>
                    </li>

                </ul>
            </div>)
        }

        {niveaux.length > 0 && nbParticipantsNonInscrits === 0 && (<>Tous les participants sont inscrits</>)}

    </Fragment>
}