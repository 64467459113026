import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom";
import { getParticipantParDossard, getParticipants, initialParticipant, IParticipant, modifieParticipant, supprimeParticipant } from "../../modele";
import { useState } from "react";

type loaderData = {
    participant: IParticipant;
    participants: IParticipant[];
}


export const loader: LoaderFunction = async ({ params }): Promise<loaderData> => {
    const participant = await getParticipantParDossard(parseInt(params.dossardID || '0'));
    let participants = await getParticipants();
    return { participant, participants };
}

export function ParticipantDetail() {
    const { participant, participants } = useLoaderData() as loaderData;
    const navigate = useNavigate();

    const [modeEdition, setModeEdition] = useState(participant.dossard === 0);

    const [nom, setNom] = useState(participant.nom);
    const [prenom, setPrenom] = useState(participant.prenom);
    const [sexe, setSexe] = useState(participant.sexe);
    const [classe, setClasse] = useState(participant.classe);
    const [dossard, setDossard] = useState(participant.dossard);
    const [statut, setStatut] = useState(participant.statut);

    const statutsPossibles = [
        'Partant', 'Dispensé aidant', 'Dispensé non aidant', 'Absence justifiée', 'Absence injustifiée', 'Abandon'
    ];

    return <>
        <p className='actions'>
            {
                modeEdition === false && <input type='button' value='Modifier' onClick={() => setModeEdition(true)}></input>
            }
            {modeEdition && <input type='button' value='Annuler' onClick={() => { setModeEdition(false); navigate(-1) }}></input>}
            {modeEdition && <input type='button' value='Valider' onClick={async () => {
                if (nom === '') {
                    alert('Veuillez saisir le nom');
                    return;
                }
                if (prenom === '') {
                    alert('Veuillez saisir le prénom');
                    return;
                }
                if ((sexe !== 'F') && (sexe !== 'M')) {
                    alert('Veuillez saisir le sexe');
                    return;
                }
                if (classe === '') {
                    alert('Veuillez saisir la classe');
                    return;
                }
                if (dossard === 0) {
                    alert('Veuillez saisir le dossard');
                    return;
                }
                if (participant.dossard !== dossard) {
                    if (participants.find((item) => {
                        return (item.dossard === dossard);
                    }) !== undefined) {
                        alert('Ce dossard est déjà saisi');
                        return;
                    }
                }
                await modifieParticipant(participant.dossard, {
                    nom, prenom, sexe,
                    classe: classe,
                    niveau: classe.length > 2 ? classe.substring(0, 3) : classe.substring(0, 1), // "CM2 Novalaise" => "CM2", "6A" => "6",
                    dossard, statut
                });
                navigate(-1)
            }}></input>}

            {modeEdition && participant.dossard !== 0 && <input type='button' value='Supprimer' onClick={async () => {
                await supprimeParticipant(participant.dossard);
                navigate(-1);
            }}></input>}
        </p>

        <ul className="champsParticipant">
            <li><span>Nom</span>
                <input autoFocus type='text' value={nom} onChange={(event) => setNom(event.target.value)} disabled={!modeEdition}></input>
            </li>
            <li><span>Prénom</span>
                <input type='text' value={prenom} onChange={(event) => setPrenom(event.target.value)} disabled={!modeEdition}></input>
            </li>
            <li><span>Sexe</span>
                <input type='text' value={sexe} onChange={(event) => setSexe(event.target.value)} disabled={!modeEdition}></input> (F ou M)
            </li>
            <li><span>Classe</span>
                <input type='text' value={classe} onChange={(event) => setClasse(event.target.value)} disabled={!modeEdition}></input> (exemple : 3A ou CM2)
            </li>
            <li><span>Dossard</span>
                <input type='number' value={dossard} onChange={(event) => setDossard(parseInt(event.target.value))} disabled={!modeEdition}></input>
            </li>
            <li><span>Statut</span>
                <select value={statut} onChange={(event) => { setStatut(event.target.value as IParticipant['statut']); setModeEdition(true); }}>
                    {statutsPossibles.map(item => <option value={item} key={item}>{item}</option>)}
                </select>
            </li>
        </ul>
    </>;
}