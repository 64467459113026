import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { ajouteCourse, ICourse, supprimeCourse } from "../modele";

type ICourseParams = {
    course: ICourse;
    afficheSuppression: boolean;
    arrivees: number[];
};

export default function CourseDetail({ course, afficheSuppression, arrivees }: ICourseParams) {
    const navigate = useNavigate();

    async function onSupprimerCourse() {
        //vérifie qu'aucune arrivée n'a été saisie
        if (arrivees !== undefined) {
            if (arrivees.length > 0) {
                alert('Cette course a déjà commencé');
                return;
            }
        }
        if (window.confirm(`Voulez-vous vraiment supprimer la course '${course.nom}' ?`)) {
            await supprimeCourse(course.nom);
        }
    }


    return <Fragment key={course.nom} >

        <div className="coursePrete" >
            <span className='nomCourse' onClick={() => navigate(`/arrivees/${course.nom}`)}>{course.nom}</span> 

            {afficheSuppression && <button onClick={onSupprimerCourse}>Supprimer</button>}
        </div>


    </Fragment>
}