import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import { getParticipants, IParticipant } from "../../modele";

type loaderData = {
    participants: IParticipant[];
}

export async function loader() {
    const participants = await getParticipants();
    return { participants };
}

export function Participants() {
    const { participants } = useLoaderData() as loaderData;
    const navigate = useNavigate();
    return (<>
        <div className="actions">
            <NavLink to={'/participants/import'}>Importer un fichier...</NavLink>
            <button onClick={() => navigate(`/participants/0`)}>Nouveau</button>
        </div>
        <div>
            <table><thead>
                <tr>
                    <th>Dossard</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Sexe</th>
                    <th>Classe</th>
                    <th>Statut</th>
                </tr>
            </thead>
                <tbody>
                    {participants.map((participant, index) =>
                        <tr key={index} onClick={() => navigate(`/participants/${participant.dossard}`)}>
                            <td>{participant.dossard}</td>
                            <td>{participant.nom}</td>
                            <td>{participant.prenom}</td>
                            <td>{participant.sexe}</td>
                            <td>{participant.classe}</td>
                            <td>{participant.statut}</td>
                        </tr>)}
                </tbody></table>
        </div>

    </>);
}