import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './routes/root';
import { Participants } from './routes/participants/participants-liste';
import { Accueil } from './routes/accueil';
import { loader as participantsLoader } from './routes/participants/participants-liste';
import { ParticipantDetail, loader as participantLoader } from './routes/participants/participants-detail';
import ParticipantsImport from './routes/participants/participants-import';
import Listings, { loader as listingsLoader } from './routes/listings';
import CoursesListe, { loader as coursesLoader, action as coursesAction } from './routes/courses';
import ArriveeListe, {loader as arriveeListeLoader} from './routes/arrivees';
import CourseArrivee, {loader as courseArriveeLoader, action as courseArriveeAction} from './Components/CourseArrivee';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Accueil />,
      },
      {
        path: "/participants",
        element: <Participants />,
        loader: participantsLoader,
      },
      {
        path: "/participants/:dossardID",
        element: <ParticipantDetail />,
        loader: participantLoader,
      },
      {
        path: "/participants/import",
        element: <ParticipantsImport />,
      },
      {
        path: "/courses",
        element: <CoursesListe />,
        loader: coursesLoader,
        action: coursesAction,
      },
      {
        path: "/arrivees",
        element: <ArriveeListe />,
        loader: arriveeListeLoader,
      },
      {
        path: "/arrivees/:courseID",
        element: <CourseArrivee />,
        loader: courseArriveeLoader,
        action: courseArriveeAction,
      },
      {
        path: "/listings",
        element: <Listings />,
        loader: listingsLoader,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
