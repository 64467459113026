import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import CourseArriveeDetail from "../Components/CourseArriveeDetail";
import { getCourses, ICourse } from "../modele";

type loaderData = {
    courses: ICourse[];
}

export async function loader() {
    const courses = await getCourses();

    return { courses };
}

export default function ArriveeListe() {
    const navigate = useNavigate();
    const {courses} = useLoaderData() as loaderData;

    return <>
      
        {courses.map((item) => {
            return  <CourseArriveeDetail course={item} key={item.nom}/>;
        })
        }
        
    </>;
}