/* Un participant à une course */
export interface IParticipant {
    nom: string;
    prenom: string;
    sexe: string;
    niveau: string;
    classe: string;
    dossard: number;
    statut: 'Partant' | 'Dispensé aidant' | 'Dispensé non aidant' | 'Absence justifiée' | 'Absence injustifiée' | 'Abandon';
}

/* Une course */
export interface ICourse {
    nom: string;
    criteresNiveaux: string[];
    criteresSexe: string[];
}

/* Le modèle de données */
export interface IModel {
    // Propriétés du modèle
    participants: IParticipant[];
    courses: ICourse[];
    arrivees: any; // Objet avec une propriété pour chaque course (nommée du nom de la course)
}

/* L'état initial du modèle */
const initialModel: IModel = {
    participants: [] as IParticipant[],
    courses: [
        {
            nom: 'CM2 et 6èmes Filles',
            criteresNiveaux: ['CM2', '6'],
            criteresSexe: ['F'],
        },
        {
            nom: 'CM2 et 6èmes Garçons',
            criteresNiveaux: ['CM2', '6'],
            criteresSexe: ['M'],
        },
        {
            nom: '5° Filles et Garçons',
            criteresNiveaux: ['5'],
            criteresSexe: ['F', 'M'],
        },
        {
            nom: '4° et 3° Filles',
            criteresNiveaux: ['4', '3'],
            criteresSexe: ['F'],
        },
        {
            nom: '4° et 3° Garçons',
            criteresNiveaux: ['4', '3'],
            criteresSexe: ['M'],
        },

    ],
    arrivees: {},
};

/* Un participant non encore saisi */
export const initialParticipant: IParticipant = {
    nom: '',
    prenom: '',
    sexe: '',
    niveau: '',
    classe: '',
    dossard: 0,
    statut: 'Partant',
}

export async function getModel(): Promise<IModel> {
    if (localStorage['crossmanager_v4'] === undefined)
        return initialModel;
    return JSON.parse(localStorage['crossmanager_v4']) as IModel;
}

export async function getParticipants(): Promise<IParticipant[]> {
    const { participants } = await getModel();
    return participants;
}

export async function getCourses(): Promise<ICourse[]> {
    const { courses } = await getModel();
    return courses;
}

export async function getParticipantParDossard(dossard: number): Promise<IParticipant> {
    const { participants } = await getModel();
    return participants.find((candidat) => candidat.dossard === dossard) || initialParticipant;
}

/* Trie les participants par classe, nom, prénom (initialement pour simplifier l'impression des dossards) */
function trierParticipants(a: IParticipant, b: IParticipant): number {
    // Classe
    if (a.classe > b.classe) {
        return 1;
    }
    if (a.classe < b.classe) {
        return -1;
    }
    // Nom
    if (a.nom > b.nom) {
        return 1;
    }
    if (a.nom < b.nom) {
        return -1;
    }
    // Prénom
    if (a.prenom > b.prenom) {
        return 1;
    }
    if (a.prenom < b.prenom) {
        return -1;
    }

    return 0;
};

export async function importeDonnees(donnees: any) {
    let model = await getModel();

    /* exemple de donnees : 
         {
                 nomHeader: 'NOM',
                 prenomHeader: 'PRENOM',
                 sexeHeader: 'SEXE',
                 classeHeader: 'DIV.',
                 dossardHeader: null,
                 donneesAImporter:[
                 {
                     "DIV.": "3A",
                     "NE(E) LE": 38810,
                     "NOM": "TARTEMPION",
                     "PRENOM": "Jaques",
                     "SEXE": "M"
                 },
                 {
                     "DIV.": "3A",
                     "NE(E) LE": 38596,
                     "NOM": "BON",
                     "PRENOM": "Jean",
                     "SEXE": "M"
                 },
                 ...
             ]
             */
    let nouveauxParticipants: IParticipant[] = donnees.donneesAImporter.map((item: any, index: Number) => {
        return {
            nom: item[donnees.nomHeader],
            prenom: item[donnees.prenomHeader],
            sexe: item[donnees.sexeHeader].substring(0,1), // "F" ou "M"    
            classe: item[donnees.classeHeader],
            niveau: item[donnees.classeHeader].length > 2 ? item[donnees.classeHeader].substring(0, 3) : item[donnees.classeHeader].substring(0, 1), // "CM2 Novalaise" => "CM2", "6A" => "6"
            dossard: donnees.dossardHeader === null ? 0 : item[donnees.dossardHeader],
            statut: 'Partant',
        } as IParticipant;
    });

    // Trie par classe, nom, prénom (pour l'affectation des dossards)
    nouveauxParticipants.sort(trierParticipants);

    //Affecte les dossards
    let precedentNiveau = 0;
    let dossard = 0;
    //console.log(donnees);

    //TODO(v2): Déterminer les plus hauts numéros de dossards déjà attribués, par niveau, pour permettre un import d'un second fichier de CM2 après un premier.
    //let dossardsParNiveau = {}; analyser les participants actuels pour remplir cet objet, et se baser sur cet objet plus bas pour calculer le numéro de dossard

    nouveauxParticipants = nouveauxParticipants.map((item, index) => {
        let niveauActuel = parseInt(item.niveau);
        if (isNaN(niveauActuel)) { // Cas des CM2
            niveauActuel = 7;
        }
        if (niveauActuel !== precedentNiveau) {
            dossard = niveauActuel * 100 + 1 // 3A : 301, 6B: 601, CM2 : 701
        }
        item.dossard = dossard++;
        // Cas du dépassement d'élèves : 699 puis 6100 plutôt que 699 puis 700 (conserve les plages)
        if (dossard === (niveauActuel + 1) * 100) { // On passe de 699 à 700 (à noter qu'on n'aura donc jamais de 6000, on passe de 699 à 6101)
            dossard = niveauActuel * 1000 + 100; // 3A : 3100, 6B: 6100, CM2 : 7100
        }
        precedentNiveau = niveauActuel;
        return item;
    });

    // Renvoi le nouveau modèle
    let newModel = {
        participants: [...model.participants, ...nouveauxParticipants].sort(trierParticipants),
        courses: [...model.courses],
        arrivees: { ...model.arrivees },
    } as IModel;
    saveModel(newModel);
}

function saveModel(model: IModel) {
    localStorage["crossmanager_v4"] = JSON.stringify(model);
}

export async function modifieParticipant(ancienDossard: number, nouveauParticipant: IParticipant) {
    let model = await getModel();
    let newParticipants = [...model.participants];
    let participant = newParticipants.find((item) => {
        return (item.dossard === ancienDossard);
    });
    if (participant === undefined) {
        participant = {
            nom: nouveauParticipant.nom,
            prenom: nouveauParticipant.prenom,
            sexe: nouveauParticipant.sexe,
            classe: nouveauParticipant.classe,
            niveau: nouveauParticipant.niveau,
            dossard: nouveauParticipant.dossard,
            statut: nouveauParticipant.statut,
        };
        newParticipants.push(participant);
    } else {
        participant.nom = nouveauParticipant.nom;
        participant.prenom = nouveauParticipant.prenom;
        participant.sexe = nouveauParticipant.sexe;
        participant.classe = nouveauParticipant.classe;
        participant.niveau = nouveauParticipant.niveau;
        participant.statut = nouveauParticipant.statut;
        participant.dossard = nouveauParticipant.dossard;
    }

    let newModel = {
        participants: [...newParticipants].sort(trierParticipants),
        courses: [...model.courses],
        arrivees: { ...model.arrivees },
    } as IModel;

    saveModel(newModel);
}

export async function supprimeParticipant(dossard: number) {
    let model = await getModel();
    let newParticipantsSupp = [...model.participants];
    let index = newParticipantsSupp.findIndex((item) => {
        return (item.dossard === dossard);
    });
    if (index >= 0) {
        newParticipantsSupp.splice(index, 1);
    }
    let newModel = {
        participants: newParticipantsSupp.sort(trierParticipants),
        courses: [...model.courses],
        arrivees: { ...model.arrivees },
    } as IModel;

    saveModel(newModel);
}

export async function ajouteCourse(course: ICourse) {
    let model = await getModel();
    let newModel = {
        participants: [...model.participants],
        courses: [...model.courses, course],
        arrivees: { ...model.arrivees },
    }
    saveModel(newModel);
}

export async function supprimeCourse(nom: string) {
    let model = await getModel();
    let newModel = {
        participants: [...model.participants],
        courses: [...model.courses],
        arrivees: { ...model.arrivees },
    }
    const indiceCourse = newModel.courses.findIndex((courseCandidate) => {
        if (courseCandidate.nom === nom) {
            return true;
        }
        return false;
    });
    if (indiceCourse >= 0) {
        newModel.courses.splice(indiceCourse, 1);
        newModel.arrivees[nom] = undefined;
    }
    saveModel(newModel);
}


export async function lireParticipantsACourse(course: ICourse): Promise<IParticipant[]> {
    let model = await getModel();
    return _lireParticipantsACourseSync(model, course);
}

function _lireParticipantsACourseSync(model: IModel, course: ICourse): IParticipant[] {
    let participants: IParticipant[] = model.participants.filter((participant) => {
        if (course.criteresNiveaux.find((critere) => {
            let niveauParticipant = participant.niveau;
            return niveauParticipant === critere;
        }) !== undefined) {
            if (course.criteresSexe.find((critere) => participant.sexe === critere) !== undefined) {
                return true;
            }
        }
        return false;
    });
    return participants;
}

export async function dispatch(type: string, payload: any) {
    let model = await getModel();
    switch (type) {
        case 'arrivee': {
            let newModel = {
                participants: [...model.participants],
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            }
            if (newModel.arrivees[payload.courseID] === undefined) {
                newModel.arrivees[payload.courseID] = [];
            }
            if (newModel.arrivees[payload.courseID].indexOf(payload.dossard) < 0) {
                newModel.arrivees[payload.courseID].unshift(payload.dossard);
            }
            // un participant ayant été saisi comme abandon peut (après validation d'un message) être réintégré dans la course
            let participant = newModel.participants.find((item) => item.dossard === payload.dossard);
            if (participant !== undefined) {
                participant.statut = 'Partant';
            }
            saveModel(newModel);
        }
            break;
        case 'abandon': {
            let newModel = {
                participants: [...model.participants],
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            };
            let participant = newModel.participants.find((item) =>
                item.dossard === payload.dossard
            );
            if (participant !== undefined) {
                participant.statut = 'Abandon';
            } else {
                console.error('abandon non trouvé');
            }
            saveModel(newModel);
        }
            break;
        case 'arriveeAnnule': {
            let newModel = {
                participants: [...model.participants],
                courses: [...model.courses],
                arrivees: { ...model.arrivees },
            }
            let listeArrivees: number[] = newModel.arrivees[payload.nom];
            if (listeArrivees !== undefined) {
                let nbLignes = listeArrivees.length;
                if (nbLignes > 0) {
                    if (listeArrivees[0] === payload.dossard) {
                        listeArrivees.shift();
                    }
                }
            }
            saveModel(newModel);
        }
            break;

    }

}